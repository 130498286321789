@import '../../styles/colors';
@import '../../styles/screens';
@import '../../styles/dimensions';

.snackbar {
    display: flex;
    margin: 0 $gap-medium;
    padding: $gap-small;
    z-index: var(--snackbar-z-index);

    @include screen-min-width('medium') {
        justify-content: center;
        margin: $gap-medium auto;
        max-width: $snackbar-max-width;
    }
}

.visible {
    pointer-events: all;
}

.snackbar:focus,
.snackbar:hover {
    @include hoverme($scale: 1.03);
    outline: none;
}

.snackbar:active {
    opacity: 0.5;
}

.snackbar:empty {
    display: none;
}
